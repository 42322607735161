@font-face {
  font-family: 'Syne';
  src: url('/assets/fonts/Syne/Syne-Regular.eot');
  src: url('/assets/fonts/Syne/Syne-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Syne/Syne-Regular.woff2') format('woff2'),
    url('/assets/fonts/Syne/Syne-Regular.woff') format('woff'),
    url('/assets/fonts/Syne/Syne-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('/assets/fonts/Syne/Syne-Medium.eot');
  src: url('/assets/fonts/Syne/Syne-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Syne/Syne-Medium.woff2') format('woff2'),
    url('/assets/fonts/Syne/Syne-Medium.woff') format('woff'),
    url('/assets/fonts/Syne/Syne-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('/assets/fonts/Syne/Syne-SemiBold.eot');
  src: url('/assets/fonts/Syne/Syne-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Syne/Syne-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/Syne/Syne-SemiBold.woff') format('woff'),
    url('/assets/fonts/Syne/Syne-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('/assets/fonts/Syne/Syne-Bold.eot');
  src: url('/assets/fonts/Syne/Syne-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Syne/Syne-Bold.woff2') format('woff2'),
    url('/assets/fonts/Syne/Syne-Bold.woff') format('woff'),
    url('/assets/fonts/Syne/Syne-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('/assets/fonts/Syne/Syne-ExtraBold.eot');
  src: url('/assets/fonts/Syne/Syne-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Syne/Syne-ExtraBold.woff2') format('woff2'),
    url('/assets/fonts/Syne/Syne-ExtraBold.woff') format('woff'),
    url('/assets/fonts/Syne/Syne-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Zilla Slab';
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-BoldItalic.eot');
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-BoldItalic.woff') format('woff'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Zilla Slab';
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-Italic.eot');
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-Italic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Italic.woff2') format('woff2'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Italic.woff') format('woff'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Zilla Slab';
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-Bold.eot');
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Bold.woff2') format('woff2'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Bold.woff') format('woff'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Zilla Slab';
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-Light.eot');
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Light.woff2') format('woff2'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Light.woff') format('woff'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Zilla Slab';
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-MediumItalic.eot');
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-MediumItalic.woff2') format('woff2'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-MediumItalic.woff') format('woff'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Zilla Slab';
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-Medium.eot');
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Medium.woff2') format('woff2'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Medium.woff') format('woff'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Zilla Slab';
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-LightItalic.eot');
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-LightItalic.woff') format('woff'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Zilla Slab';
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-Regular.eot');
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Regular.woff2') format('woff2'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Regular.woff') format('woff'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Zilla Slab';
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-SemiBoldItalic.eot');
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-SemiBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-SemiBoldItalic.woff2') format('woff2'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-SemiBoldItalic.woff') format('woff'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Zilla Slab';
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-SemiBold.eot');
  src: url('/assets/fonts/Zilla-Slab/ZillaSlab-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-SemiBold.woff') format('woff'),
    url('/assets/fonts/Zilla-Slab/ZillaSlab-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Silkscreen';
  src: url('/assets/fonts/Silkscreen/Silkscreen-Bold.eot');
  src: url('/assets/fonts/Silkscreen/Silkscreen-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Silkscreen/Silkscreen-Bold.woff2') format('woff2'),
    url('/assets/fonts/Silkscreen/Silkscreen-Bold.woff') format('woff'),
    url('/assets/fonts/Silkscreen/Silkscreen-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Silkscreen';
  src: url('/assets/fonts/Silkscreen/Silkscreen-Regular.eot');
  src: url('/assets/fonts/Silkscreen/Silkscreen-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Silkscreen/Silkscreen-Regular.woff2') format('woff2'),
    url('/assets/fonts/Silkscreen/Silkscreen-Regular.woff') format('woff'),
    url('/assets/fonts/Silkscreen/Silkscreen-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Six Caps';
  src: url('/assets/fonts/Six/SixCaps.eot');
  src: url('/assets/fonts/Six/SixCaps.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Six/SixCaps.woff2') format('woff2'),
    url('/assets/fonts/Six/SixCaps.woff') format('woff'),
    url('/assets/fonts/Six/SixCaps.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dela Gothic One';
  src: url('/assets/fonts/Dela/DelaGothicOne-Regular.eot');
  src: url('/assets/fonts/Dela/DelaGothicOne-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Dela/DelaGothicOne-Regular.woff2') format('woff2'),
    url('/assets/fonts/Dela/DelaGothicOne-Regular.woff') format('woff'),
    url('/assets/fonts/Dela/DelaGothicOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
